// src/services/data.js
import questionData from '../data/questions.json';

export const questionService = {
  // 获取所有题目
  getAllQuestions: async () => {
    console.log('Loading questions:', questionData.questions); // 检查数据是否正确加载
    return questionData.questions;
  },

  // 根据类型获取题目
  getQuestionsByType: async (type) => {
    return questionData.questions.filter(q => q.type === type);
  },

  // 获取随机题目（用于模拟测试）
  getRandomQuestions: async (type, count) => {
    const typeQuestions = questionData.questions.filter(q => q.type === type);
    const shuffled = [...typeQuestions].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
  }
};

export const storageService = {
  // 保存错题记录
  saveWrongQuestions: async (wrongQuestions) => {
    try {
      const wrongQuestionsStr = JSON.stringify(wrongQuestions);
      localStorage.setItem('wrongQuestions', wrongQuestionsStr);
      console.log('Saved wrong questions:', wrongQuestions); // 添加日志
      return true;
    } catch (error) {
      console.error('Error saving wrong questions:', error);
      throw error;
    }
  },

  // 获取错题记录
  getWrongQuestions: async () => {
    try {
      const wrongQuestions = localStorage.getItem('wrongQuestions');
      console.log('Retrieved wrong questions:', wrongQuestions); // 添加日志
      return wrongQuestions ? JSON.parse(wrongQuestions) : [];
    } catch (error) {
      console.error('Error getting wrong questions:', error);
      throw error;
    }
  },
};