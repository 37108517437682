import React, { useState, useEffect } from 'react';
import { ChevronLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import QuestionCard from './QuestionCard';
import { useQuestions } from '../contexts/QuestionContext';

export default function PracticeWrong() {
  const navigate = useNavigate();
  const { recordAnswer } = useQuestions();
  const [questions, setQuestions] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);  

  useEffect(() => {
    // 从 sessionStorage 获取要练习的题目
    const practiceQuestions = sessionStorage.getItem('practiceQuestions');
    if (!practiceQuestions) {
      navigate('/wrong-questions');
      return;
    }
    setQuestions(JSON.parse(practiceQuestions));
  }, [navigate]);

  const handleBack = () => {
    // 如果正在练习(不是第一题),显示确认框
    if (currentIndex > 0) {
      if (window.confirm('确定要退出练习吗？当前进度将不会保存。')) {
        navigate('/'); // 返回主页
      }
    } else {
      // 如果是第一题,直接返回主页
      navigate('/');
    }
  };

  const handleAnswer = async (selectedOption) => {
    const currentQuestion = questions[currentIndex];
    const isCorrect = `option_${selectedOption}` === currentQuestion.answer;
    
    // 记录答题
    recordAnswer(currentQuestion.id, selectedOption, isCorrect);
  
    // 延迟后进入下一题或结束
    setTimeout(() => {
      if (currentIndex < questions.length - 1) {
        setCurrentIndex(currentIndex + 1);
      } else {
        navigate('/wrong-questions');
      }
    }, 1000);  // 可以缩短延迟时间，因为不需要等待反馈显示了
  };

  if (questions.length === 0) return null;

  return (
    <div className="min-h-screen bg-gray-50 pb-20">
      {/* Header */}
      <div className="bg-white">
        <div className="flex items-center px-4 py-4">
          <button 
            onClick={handleBack}
            className="p-2 hover:bg-gray-100 rounded-lg"
          >
            <ChevronLeft className="w-6 h-6 text-gray-600" />
          </button>
          <h1 className="ml-2 text-lg font-semibold text-gray-800">错题练习</h1>
          <span className="ml-auto text-sm text-gray-500">
            {currentIndex + 1}/{questions.length}
          </span>
        </div>
      </div>

      {/* Question Area */}
      <div className="p-4">
        <QuestionCard 
          question={questions[currentIndex]}
          onSelect={handleAnswer}
        />
      </div>

      {/* Feedback Overlay */}

      {/* Progress Bar */}
      <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-100 p-4">
        <div className="h-1 bg-gray-200 rounded-full">
          <div 
            className="h-1 bg-indigo-600 rounded-full transition-all"
            style={{ width: `${(currentIndex + 1) / questions.length * 100}%` }}
          />
        </div>
      </div>
    </div>
  );
}