import React, { useState, useEffect } from 'react';
import { ChevronLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useQuestions } from '../contexts/QuestionContext';
import QuestionCard from './QuestionCard';

export default function PracticeFavorites() {
  const navigate = useNavigate();
  const { recordAnswer } = useQuestions();
  const [questions, setQuestions] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showFeedback, setShowFeedback] = useState(false);
  const [feedbackContent, setFeedbackContent] = useState({ isCorrect: false, message: '' });

  useEffect(() => {
    // 从 sessionStorage 获取要练习的题目
    const practiceQuestions = sessionStorage.getItem('practiceQuestions');
    if (!practiceQuestions) {
      navigate('/favorites');
      return;
    }
    setQuestions(JSON.parse(practiceQuestions));
  }, [navigate]);

  const handleAnswer = (selectedOption) => {
    const currentQuestion = questions[currentIndex];
    const userAnswer = `option_${selectedOption}`;
    const isCorrect = userAnswer === currentQuestion.answer;
    
    // 记录答题
    recordAnswer(currentQuestion.id, selectedOption, isCorrect);
    
    // 显示反馈
    setFeedbackContent({
      isCorrect,
      message: isCorrect ? '答对了！' : '答错了',
      correctAnswer: currentQuestion[`${currentQuestion.answer}_zh`]
    });
    setShowFeedback(true);

    // 延迟后进入下一题或结束
    setTimeout(() => {
      setShowFeedback(false);
      if (currentIndex < questions.length - 1) {
        setCurrentIndex(currentIndex + 1);
      } else {
        navigate('/favorites');
      }
    }, 1500);
  };

  if (questions.length === 0) return null;

  return (
    <div className="min-h-screen bg-gray-50 pb-20">
      {/* Header */}
      <div className="bg-white">
        <div className="flex items-center px-4 py-4">
          <button 
            onClick={() => navigate('/favorites')}
            className="p-2 hover:bg-gray-100 rounded-lg"
          >
            <ChevronLeft className="w-6 h-6 text-gray-600" />
          </button>
          <h1 className="ml-2 text-lg font-semibold text-gray-800">收藏题练习</h1>
          <span className="ml-auto text-sm text-gray-500">
            {currentIndex + 1}/{questions.length}
          </span>
        </div>
      </div>

      {/* Question Area */}
      <div className="p-4">
        <QuestionCard 
          question={questions[currentIndex]}
          onSelect={handleAnswer}
        />
      </div>

      {/* Feedback Overlay */}
      {showFeedback && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className={`
            p-6 rounded-lg text-white text-lg
            ${feedbackContent.isCorrect ? 'bg-green-500' : 'bg-red-500'}
          `}>
            <div className="text-center">
              <div className="text-xl font-bold mb-2">{feedbackContent.message}</div>
              {!feedbackContent.isCorrect && (
                <div className="text-sm">
                  正确答案：{feedbackContent.correctAnswer}
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Progress Bar */}
      <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-100 p-4">
        <div className="h-1 bg-gray-200 rounded-full">
          <div 
            className="h-1 bg-indigo-600 rounded-full transition-all"
            style={{ width: `${(currentIndex + 1) / questions.length * 100}%` }}
          />
        </div>
      </div>
    </div>
  );
}