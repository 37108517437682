import React from 'react';
import { CheckCircle, BookOpen, RotateCcw, Home } from 'lucide-react';

export default function CompletionModal({ 
  stats = { total: 0, correct: 0, wrong: 0 },
  onReview,  // 复习错题
  onRestart, // 重新开始
  onExit,    // 返回主页
  showReview = true, // 是否显示复习选项
  mode = 'practice' // practice | mock | targeted
}) {
  const percentage = Math.round((stats.correct / stats.total) * 100) || 0;
  const hasPassed = percentage >= 80; // 通过率标准
  
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
  
      <div className="bg-white rounded-xl p-6 max-w-md w-full mx-4">
        {/* Header */}
        <div className="text-center mb-6">
          <div className={`w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-4 
            ${hasPassed ? 'bg-green-100' : 'bg-red-100'}`}>
            <CheckCircle className={`w-8 h-8 ${hasPassed ? 'text-green-500' : 'text-red-500'}`} />
          </div>
          <h2 className="text-xl font-semibold text-gray-800">
            {mode === 'mock' ? '模拟测试完成！' : '练习完成！'}
          </h2>
          {mode === 'mock' && (
            <p className="text-lg mt-1">
              {hasPassed ? (
                <span className="text-green-600">恭喜通过！</span>
              ) : (
                <span className="text-red-600">未能通过，继续加油！</span>
              )}
            </p>
          )}
        </div>

        {/* Stats */}
        <div className="grid grid-cols-3 gap-4 mb-6">
          <div className="text-center p-3 bg-gray-50 rounded-lg">
            <div className="text-lg font-semibold text-gray-800">{stats.total}</div>
            <div className="text-sm text-gray-500">总题数</div>
          </div>
          <div className="text-center p-3 bg-gray-50 rounded-lg">
            <div className="text-lg font-semibold text-green-600">{stats.correct}</div>
            <div className="text-sm text-gray-500">答对</div>
          </div>
          <div className="text-center p-3 bg-gray-50 rounded-lg">
            <div className="text-lg font-semibold text-red-600">{stats.wrong}</div>
            <div className="text-sm text-gray-500">答错</div>
          </div>
        </div>

        {/* Percentage */}
        <div className="mb-6">
          <div className="flex justify-between items-center mb-2">
            <span className="text-sm text-gray-600">正确率</span>
            <span className="text-sm font-medium text-gray-800">{percentage}%</span>
          </div>
          <div className="h-2 bg-gray-100 rounded-full">
            <div 
              className={`h-2 rounded-full transition-all ${
                hasPassed ? 'bg-green-500' : 'bg-red-500'
              }`}
              style={{ width: `${percentage}%` }}
            />
          </div>
        </div>

        {/* 错题练习提示 */}
        {stats.wrong > 0 && (
          <div className="mb-4 p-4 bg-yellow-50 rounded-lg">
            <p className="text-yellow-700 text-sm">
              您有 {stats.wrong} 道题答错了，要练习这些错题吗？
            </p>
          </div>
        )}

        {/* Actions */}
        <div className="space-y-3">
          {/* 错题练习按钮 */}
          {showReview && stats.wrong > 0 && (
            <button
              onClick={onReview}
              className="w-full bg-indigo-600 text-white py-3 rounded-lg hover:bg-indigo-700 
                flex items-center justify-center space-x-2"
            >
              <BookOpen className="w-5 h-5" />
              <span>练习错题 ({stats.wrong}题)</span>
            </button>
          )}
          
          {/* 重新开始按钮 */}
          <button
            onClick={onRestart}
            className="w-full border border-gray-200 text-gray-700 py-3 rounded-lg hover:bg-gray-50
              flex items-center justify-center space-x-2"
          >
            <RotateCcw className="w-5 h-5" />
            <span>重新{mode === 'mock' ? '测试' : '练习'}</span>
          </button>
          
          {/* 返回主页按钮 */}
          <button
            onClick={() => {
              // 确保所有状态都已保存后再返回主页
              setTimeout(() => {
                onExit();
              }, 100);
            }}
            className="w-full border border-gray-200 text-gray-700 py-3 rounded-lg hover:bg-gray-50
              flex items-center justify-center space-x-2"
          >
            <Home className="w-5 h-5" />
            <span>返回主页</span>
          </button>
        </div>

        {/* 模拟考试额外提示 */}
        {mode === 'mock' && (
          <div className="mt-4 text-center text-sm text-gray-500">
            {hasPassed ? (
              '您已达到通过标准，可以预约正式考试了！'
            ) : (
              '继续练习，下次一定能通过！'
            )}
          </div>
        )}
      </div>
    </div>
  );
}