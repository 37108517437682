// src/components/QuestionCard.js

import React, { useState, useEffect } from 'react';
import { Heart, Globe } from 'lucide-react';
import { useQuestions } from '../contexts/QuestionContext';

export default function QuestionCard({ 
  question, 
  onSelect,
  showFavoriteButton = true,
  showImage = true,
}) {
  const { toggleFavorite, isFavorite, language, toggleLanguage } = useQuestions();
  const [selectedOption, setSelectedOption] = useState(null);
  const [showFeedback, setShowFeedback] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  // 重置状态当题目改变时
  useEffect(() => {
    setSelectedOption(null);
    setShowFeedback(false);
    setIsProcessing(false);
  }, [question]);

  const getOptionClass = (option) => {
    const baseClass = `w-full p-4 rounded-lg border transition-colors
      flex items-center space-x-3 ${showImage ? 'mb-3' : 'mb-2'}`;

    if (!selectedOption) {
      return `${baseClass} border-gray-200 ${!isProcessing && 'hover:bg-gray-50'}`;
    }

    const isSelected = selectedOption === option;
    const isCorrect = `option_${option}` === question.answer;

    if (showFeedback) {
      if (isCorrect) {
        return `${baseClass} border-green-500 bg-green-50 text-green-700`;
      }
      if (isSelected && !isCorrect) {
        return `${baseClass} border-red-500 bg-red-50 text-red-700`;
      }
      if (isCorrect && !isSelected) {
        return `${baseClass} border-green-500 bg-green-50 text-green-700`;
      }
    }

    return `${baseClass} border-gray-200`;
  };

  const handleOptionClick = async (option) => {
    if (isProcessing || selectedOption) return;

    setIsProcessing(true);
    setSelectedOption(option);
    
    const isCorrect = `option_${option}` === question.answer;
    setShowFeedback(true);

    if (isCorrect) {
      setTimeout(() => {
        onSelect(option);
      }, 1000);
    } else {
      setTimeout(() => {
        onSelect(option);
      }, 1500);
    }
  };

  const getButtonContent = (option) => (
    <>
      <div className="w-8 h-8 rounded-full bg-gray-100 flex items-center justify-center shrink-0">
        <span className="text-sm font-medium text-gray-600">
          {option.toUpperCase()}
        </span>
      </div>
      <div className="flex-1 text-left">
        <p className="text-sm">
          {question[`option_${option}_${language}`]}
        </p>
      </div>
    </>
  );

  return (
    <div className="bg-white rounded-xl p-5">
      {/* Header Section */}
      <div className="flex justify-between items-start mb-4">
        <div className="flex items-center space-x-2">
          <span className="text-sm text-gray-500">
            题目 {question.id}
          </span>
          <span className="text-sm text-gray-400">
            {question.type === "1" ? "路标题" : "路规题"}
          </span>
        </div>
        <div className="flex items-center space-x-2">
          <button 
            onClick={() => toggleLanguage()}
            className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
            title={language === 'zh' ? '切换到英文' : '切换到中文'}
          >
            <Globe className="w-5 h-5 text-gray-500" />
          </button>
          {showFavoriteButton && (
            <button 
              onClick={(e) => {
                e.stopPropagation();
                toggleFavorite(question.id);
              }}
              className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
            >
              <Heart 
                className={`w-5 h-5 ${
                  isFavorite(question.id) 
                    ? 'fill-red-500 text-red-500' 
                    : 'text-gray-400'
                }`}
              />
            </button>
          )}
        </div>
      </div>

      {/* Image Section */}
      {showImage && question.type === "1" && question.image !== "none" && (
        <div className="mb-4">
          <div className="bg-gray-100 rounded-lg p-4 flex items-center justify-center">
            <div className="relative w-full h-48 max-w-[300px] mx-auto">
              <img 
                src={`/${question.image}.png`}  // 修改这里，使用 question.image
                alt="Road Sign"
                className="object-contain w-full h-full"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = '/images/placeholder.png';
                }}
              />
            </div>
          </div>
        </div>
      )}

      {/* Question Content */}
      <div className="mb-6">
        <h3 className="text-base text-gray-800 leading-relaxed">
          {question[`question_${language}`]}
        </h3>
      </div>

      {/* Options */}
      <div className="space-y-3">
        {['a', 'b', 'c', 'd'].map((option) => (
          <button
            key={option}
            className={getOptionClass(option)}
            onClick={() => handleOptionClick(option)}
            disabled={isProcessing}
          >
            {getButtonContent(option)}
          </button>
        ))}
      </div>
    </div>
  );
}