// src/components/MockTest.js

import React, { useState, useEffect } from 'react';
import { ChevronLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useQuestions } from '../contexts/QuestionContext';
import QuestionCard from './QuestionCard';
import CompletionModal from './CompletionModal';
import TestProgress from './TestProgress';

export default function MockTest() {
  const navigate = useNavigate();
  const { questions, loading, recordAnswer, recordWrongQuestions } = useQuestions();
  const [hasStarted, setHasStarted] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [testQuestions, setTestQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [showCompletion, setShowCompletion] = useState(false);
  const [practiceStats, setPracticeStats] = useState({
    total: 40,
    correct: 0,
    wrong: 0
  });

  // 初始化测试题目
  useEffect(() => {
    if (!hasStarted || questions.length === 0) {
      return;
    }

    console.log('Starting to prepare test questions');
    
    // 分别获取路标题和路规题
    const signQuestions = questions.filter(q => q.type === "1");
    const ruleQuestions = questions.filter(q => q.type === "2");

    console.log('Filtered questions:', {
      totalQuestions: questions.length,
      signs: signQuestions.length,
      rules: ruleQuestions.length
    });

    // 随机抽取题目
    const shuffledSigns = [...signQuestions].sort(() => 0.5 - Math.random());
    const shuffledRules = [...ruleQuestions].sort(() => 0.5 - Math.random());

    const selectedSignQuestions = shuffledSigns.slice(0, 20).map(q => ({
      ...q,
      testType: 'signs'
    }));

    const selectedRuleQuestions = shuffledRules.slice(0, 20).map(q => ({
      ...q,
      testType: 'rules'
    }));

    const allTestQuestions = [...selectedSignQuestions, ...selectedRuleQuestions];

    console.log('Prepared test questions:', {
      total: allTestQuestions.length,
      signs: selectedSignQuestions.length,
      rules: selectedRuleQuestions.length
    });

    setTestQuestions(allTestQuestions);
    setAnswers(new Array(allTestQuestions.length).fill(null));

  }, [hasStarted, questions]);

  useEffect(() => {
    return () => {
      setHasStarted(false);
      setTestQuestions([]);
      setAnswers([]);
      setCurrentIndex(0);
    };
  }, []);

  const handleAnswer = async (option) => {
    // 边界检查
    if (!testQuestions[currentIndex]) return;
  
    // 处理当前答题
    const currentQuestion = testQuestions[currentIndex];
    const isCorrect = `option_${option}` === currentQuestion.answer;
  
    // 记录答题并更新状态
    await recordAnswer(currentQuestion.id, option, isCorrect, 'mock');
    const newAnswers = [...answers];
    newAnswers[currentIndex] = {
      type: currentQuestion.testType,
      isCorrect,
      selectedOption: option
    };
    setAnswers(newAnswers);
  
    // 设置延迟时间
    const delay = 1000;
  
    // 如果是最后一题，才进行统计和判断通过情况
    if (currentIndex === testQuestions.length - 1) {
      setTimeout(async () => {
        // 收集并记录错题
        const wrongQuestions = testQuestions.filter((_, index) => !newAnswers[index]?.isCorrect);
        if (wrongQuestions.length > 0) {
          await recordWrongQuestions(wrongQuestions);
        }
  
        // 在最后才统计错题数，之前不会检查或中断测试
        const signsWrong = newAnswers.slice(0, 20).filter(a => !a?.isCorrect).length;
        const rulesWrong = newAnswers.slice(20).filter(a => !a?.isCorrect).length;
        const totalCorrect = newAnswers.filter(a => a?.isCorrect).length;
  
        // 更新统计数据并显示完成对话框
        setPracticeStats({
          total: 40,
          correct: totalCorrect,
          wrong: 40 - totalCorrect,
          signsWrong,
          rulesWrong,
          passed: signsWrong <= 4 && rulesWrong <= 4  // 只在最后判断是否通过
        });
        setShowCompletion(true);
      }, delay);
      return;
    }
  
    // 不管错了多少题都会继续到下一题
    setTimeout(() => {
      setCurrentIndex(currentIndex + 1);
    }, delay);
  };

  if (!hasStarted) {
    return (
      <div className="min-h-screen bg-gray-50">
        <div className="bg-white">
          <div className="flex items-center px-4 py-4">
            <button 
              onClick={() => navigate(-1)}
              className="p-2 hover:bg-gray-100 rounded-lg"
            >
              <ChevronLeft className="w-6 h-6 text-gray-600" />
            </button>
            <h1 className="ml-2 text-lg font-semibold text-gray-800">模拟测试</h1>
          </div>
        </div>

        <div className="p-5">
          <div className="bg-white rounded-xl p-6">
            <h2 className="text-lg font-medium text-gray-800 mb-4">测试说明</h2>
            <div className="space-y-3 text-sm text-gray-600 mb-6">
              <p>• 共40道题目（道路标志20题，交通规则20题）</p>
              <p>• 每部分最多允许4道错题</p>
              <p>• 答题进度实时显示</p>
              <p>• 测试时间不限</p>
            </div>
            {!loading ? (
              <button 
                onClick={() => {
                  console.log('Start button clicked');
                  setHasStarted(true);
                }}
                className="w-full bg-indigo-600 text-white py-3 rounded-lg hover:bg-indigo-700 transition-colors"
              >
                开始测试
              </button>
            ) : (
              <div className="text-center text-gray-500">加载中...</div>
            )}
          </div>
        </div>
      </div>
    );
  }

  if (loading || testQuestions.length < 40) {
    console.log('Loading state:', { loading, testQuestionsLength: testQuestions.length });
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center">
          <div className="w-12 h-12 border-4 border-indigo-600 border-t-transparent rounded-full animate-spin mx-auto mb-4" />
          <div className="text-gray-500">题目准备中...</div>
        </div>
      </div>
    );
  }

  if (loading || testQuestions.length === 0) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-gray-500">题目准备中...</div>
      </div>
    );
  }

  // ... 其他代码保持不变

  return (
    <div className="min-h-screen bg-gray-50 pb-20">
      {/* Header */}
      <div className="bg-white">
        <div className="flex items-center px-4 py-4">
          <button 
            onClick={() => navigate(-1)}
            className="p-2 hover:bg-gray-100 rounded-lg"
          >
            <ChevronLeft className="w-6 h-6 text-gray-600" />
          </button>
          <h1 className="ml-2 text-lg font-semibold text-gray-800">
            模拟测试 - {currentIndex < 20 ? '路标题' : '路规题'}
          </h1>
          <span className="ml-auto text-sm text-gray-500">
            {currentIndex + 1}/40
          </span>
        </div>
      </div>

      <div className="p-4">
        {testQuestions[currentIndex] && (
          <QuestionCard 
            question={testQuestions[currentIndex]}
            onSelect={handleAnswer}
            showFavoriteButton={false}
          />
        )}
      </div>

      {/* Progress Bar */}
      <div className="fixed bottom-0 left-0 right-0">
        <TestProgress answers={answers} />
      </div>

      {/* Completion Modal */}
      {showCompletion && (
        <CompletionModal
          stats={practiceStats}
          onReview={() => {
            const wrongQuestions = testQuestions.filter((_, index) => 
              !answers[index]?.isCorrect
            );
            if (wrongQuestions.length > 0) {
              sessionStorage.setItem('practiceQuestions', JSON.stringify(wrongQuestions));
              navigate('/practice-wrong');
            }
          }}
          onRestart={() => {
            setCurrentIndex(0);
            setTestQuestions([]);
            setAnswers(Array(40).fill(null));
            setHasStarted(false);
            setShowCompletion(false);
          }}
          onExit={() => navigate('/')}
          mode="mock"
        />
      )}
    </div>
  );
}