import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './components/HomePage';
import PracticeAll from './components/PracticeAll';
import PracticeTargeted from './components/PracticeTargeted';
import PracticeSession from './components/PracticeSession';
import MockTest from './components/MockTest';
import WrongQuestions from './components/WrongQuestions';
import { QuestionProvider } from './contexts/QuestionContext';
import TestResult from './components/TestResult';
import PracticeWrong from './components/PracticeWrong';
import FavoriteQuestions from './components/FavoriteQuestions';
import PracticeFavorites from './components/PracticeFavorites';

function App() {
  return (
    <QuestionProvider>
      <Router future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/practice-all" element={<PracticeAll />} />
          <Route path="/practice-targeted" element={<PracticeTargeted />} />
          <Route path="/practice/:type" element={<PracticeSession />} />
          <Route path="/mock-test" element={<MockTest />} />
          <Route path="/wrong-questions" element={<WrongQuestions />} />
          <Route path="/practice-wrong" element={<PracticeWrong />} />
          <Route path="/test-result" element={<TestResult />} />
          <Route path="/favorites" element={<FavoriteQuestions />} />
          <Route path="/practice-favorites" element={<PracticeFavorites />} />
        </Routes>
      </Router>
    </QuestionProvider>
  );
}

export default App;