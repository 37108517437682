import React, { useState } from 'react';
import { ChevronLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useQuestions } from '../contexts/QuestionContext';
import QuestionCard from './QuestionCard';
import CompletionModal from './CompletionModal';

export default function PracticeAll() {
  const navigate = useNavigate();
  const { questions, loading, recordAnswer, recordWrongQuestions } = useQuestions();  // 添加 recordAnswer
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [showCompletion, setShowCompletion] = useState(false);
  const [practiceStats, setPracticeStats] = useState({
    total: 0,
    correct: 0,
    wrong: 0
  });

  console.log('Available questions:', questions); // 添加日志查看题目数据

  // 处理练习完成
  const handleComplete = async () => {
    // 收集所有答错的题目
    const wrongQuestions = questions.filter((_, index) => !selectedAnswers[index]?.isCorrect);
    
    // 确保所有错题都被记录
    if (wrongQuestions.length > 0) {
      await recordWrongQuestions(wrongQuestions);
    }

    // 更新统计数据
    const stats = {
      total: selectedAnswers.length,
      correct: selectedAnswers.filter(a => a.isCorrect).length,
      wrong: selectedAnswers.filter(a => !a.isCorrect).length
    };
    setPracticeStats(stats);
    setShowCompletion(true);
  };

  // 处理答题
  const handleAnswer = async (option) => {
    if (!questions[currentIndex]) return;

    const currentQuestion = questions[currentIndex];
    const isCorrect = `option_${option}` === currentQuestion.answer;

    // 1. 记录答题结果到 Context
    await recordAnswer(currentQuestion.id, option, isCorrect);

    // 2. 更新本地状态
    const newAnswers = [...selectedAnswers];
    newAnswers[currentIndex] = {
      questionId: currentQuestion.id,
      selected: option,
      isCorrect
    };
    setSelectedAnswers(newAnswers);

    // 3. 如果答错了，立即记录到错题本
    if (!isCorrect) {
      await recordWrongQuestions([currentQuestion]);
    }

    // 4. 延迟后进入下一题或完成
    const delay = 10;
    setTimeout(() => {
      if (currentIndex < questions.length - 1) {
        setCurrentIndex(prev => prev + 1);
      } else {
        handleComplete();
      }
    }, delay);
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-gray-500">加载中...</div>
      </div>
    );
  }

  if (!questions || questions.length === 0) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-gray-500">暂无题目</div>
      </div>
    );
  }

  const currentQuestion = questions[currentIndex];
  console.log('Rendering question:', currentQuestion); // 添加日志查看渲染的题目

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header */}
      <div className="bg-white">
        <div className="flex items-center px-4 py-4">
          <button 
            onClick={() => navigate('/')}
            className="p-2 hover:bg-gray-100 rounded-lg"
          >
            <ChevronLeft className="w-6 h-6 text-gray-600" />
          </button>
          <h1 className="ml-2 text-lg font-semibold text-gray-800">题库练习</h1>
          <span className="ml-auto text-sm text-gray-500">
            {currentIndex + 1}/{questions.length}
          </span>
        </div>
      </div>

      {/* Question Content */}
      <div className="p-4">
        {currentQuestion && (
          <QuestionCard
            question={currentQuestion}
            onSelect={handleAnswer}
            showFavoriteButton={false}
          />
        )}
      </div>

      {/* Progress Bar */}
      <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-100 p-4">
        <div className="h-1 bg-gray-200 rounded-full">
          <div 
            className="h-1 bg-indigo-600 rounded-full transition-all"
            style={{ width: `${(currentIndex + 1) / questions.length * 100}%` }}
          />
        </div>
      </div>

      {/* Completion Modal */}
      {showCompletion && (
        <CompletionModal
          stats={practiceStats}
          onReview={() => {
            const wrongQuestions = questions.filter((_, index) => 
              !selectedAnswers[index]?.isCorrect
            );
            if (wrongQuestions.length > 0) {
              sessionStorage.setItem('practiceQuestions', JSON.stringify(wrongQuestions));
              navigate('/practice-wrong');
            }
          }}
          onRestart={() => {
            setCurrentIndex(0);
            setSelectedAnswers([]);
            setShowCompletion(false);
          }}
          onExit={() => navigate('/')}
          mode="practice"
          showReview={practiceStats.wrong > 0}
        />
      )}
    </div>
  );
}