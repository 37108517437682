// src/contexts/QuestionContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { questionService, storageService } from '../services/data';

const QuestionContext = createContext();

export function QuestionProvider({ children }) {
  const [questions, setQuestions] = useState([]);
  const [userProgress, setUserProgress] = useState(null);
  const [wrongQuestions, setWrongQuestions] = useState([]);
  const [favorites, setFavorites] = useState(new Set());
  const [loading, setLoading] = useState({
    questions: true,
    progress: true,
    wrongQuestions: true,
    favorites: true,
  });
  const [error, setError] = useState(null);
  const [language, setLanguage] = useState('zh');

  useEffect(() => {
    const loadQuestions = async () => {
      try {
        const data = await questionService.getAllQuestions();
        setQuestions(data);
        setLoading(prev => ({ ...prev, questions: false }));
      } catch (err) {
        setError(err.message);
        setLoading(prev => ({ ...prev, questions: false }));
      }
    };
    loadQuestions();
  }, []);

  useEffect(() => {
    const loadProgress = async () => {
      try {
        const data = await storageService.getProgress();
        setUserProgress(data || { answers: {}, completed: [] });
        setLoading(prev => ({ ...prev, progress: false }));
      } catch (err) {
        setError(err.message);
        setLoading(prev => ({ ...prev, progress: false }));
      }
    };
    loadProgress();
  }, []);

  // 加载错题数据
  useEffect(() => {
    const loadWrongQuestions = async () => {
      try {
        const data = await storageService.getWrongQuestions();
        setWrongQuestions(data || []);
        setLoading(prev => ({ ...prev, wrongQuestions: false }));
      } catch (err) {
        setError(err.message);
        setLoading(prev => ({ ...prev, wrongQuestions: false }));
      }
    };
    loadWrongQuestions();
  }, []);

  // 加载收藏数据
  useEffect(() => {
    const loadFavorites = () => {
      try {
        const data = JSON.parse(localStorage.getItem('favorites') || '[]');
        setFavorites(new Set(data));
        setLoading(prev => ({ ...prev, favorites: false }));
      } catch (err) {
        setError(err.message);
        setLoading(prev => ({ ...prev, favorites: false }));
      }
    };
    loadFavorites();
  }, []);

  const clearWrongQuestions = async () => {
    try {
      await storageService.saveWrongQuestions([]);
      setWrongQuestions([]); // 使用正确的 setter
    } catch (error) {
      console.error('Error clearing wrong questions:', error);
    }
  };

  // 检查是否所有数据都加载完成
  const isAllDataLoaded = () => {
    return Object.values(loading).every(item => !item);
  };

  const recordWrongQuestions = async (newWrongQuestions) => {
    try {
      // 将新的错题合并到现有错题本,避免重复
      const mergedWrongQuestions = [...new Set([...newWrongQuestions, ...wrongQuestions])];

      // 保存到本地存储
      await storageService.saveWrongQuestions(mergedWrongQuestions);

      setWrongQuestions(mergedWrongQuestions);
    } catch (error) {
      console.error('Error recording wrong questions:', error);
      setError(error.message);
    }
  };

  // 记录答题
  const recordAnswer = async (questionId, answer, isCorrect, mode = 'practice') => {
    try {
      // 更新答题历史
      const normalizedAnswer = {
        answer: `option_${answer.toLowerCase()}`,
        isCorrect,
        timestamp: Date.now(),
      };

      const newProgress = {
        ...userProgress,
        answers: {
          ...userProgress.answers,
          [questionId]: Array.isArray(userProgress?.answers?.[questionId])
            ? [...userProgress.answers[questionId], normalizedAnswer]
            : [normalizedAnswer],
        },
      };

      // 如果答错了,添加到错题本
      if (!isCorrect) {
        const question = questions.find(q => q.id === questionId);
        if (question && !wrongQuestions.some(q => q.id === questionId)) {
          recordWrongQuestions([question]);
        }
      }

      await storageService.saveProgress(newProgress);
      setUserProgress(newProgress);
    } catch (error) {
      console.error('Error recording answer:', error);
      setError(error.message);
    }
  };

  // 切换收藏状态
  const toggleFavorite = (questionId) => {
    try {
      const newFavorites = new Set(favorites);
      if (newFavorites.has(questionId)) {
        newFavorites.delete(questionId);
      } else {
        newFavorites.add(questionId);
      }

      // 保存到本地存储
      localStorage.setItem('favorites', JSON.stringify([...newFavorites]));

      setFavorites(newFavorites);
    } catch (error) {
      console.error('Error toggling favorite:', error);
      setError(error.message);
    }
  };

  // 获取收藏的题目
  const getFavoriteQuestions = () => {
    return questions.filter(q => favorites.has(q.id)).map(q => ({
      ...q,
      question: language === 'zh' ? q.question.zh : q.question.en,
      options: q.options.map(opt => ({
        ...opt,
        text: language === 'zh' ? opt.zh : opt.en,
      })),
    }));
  };

  // 移除错题
  const removeWrongQuestion = async (questionId) => {
    try {
      const newWrongQuestions = wrongQuestions.filter(q => q.id !== questionId);
      await storageService.saveWrongQuestions(newWrongQuestions);
      setWrongQuestions(newWrongQuestions);
    } catch (error) {
      console.error('Error removing wrong question:', error);
      setError(error.message);
    }
  };

  // 清除所有数据
  const clearAllData = async () => {
    try {
      localStorage.clear();
      setUserProgress({ answers: {}, completed: [] });
      setWrongQuestions([]);
      setFavorites(new Set());
    } catch (error) {
      console.error('Error clearing data:', error);
      setError(error.message);
    }
  };

  // 切换语言
  const toggleLanguage = () => {
    setLanguage(prev => (prev === 'zh' ? 'en' : 'zh'));
  };

  // 获取随机题目(用于模拟测试)
  const getRandomQuestions = async (type, count) => {
    try {
      return await questionService.getRandomQuestions(type, count);
    } catch (error) {
      console.error('Error getting random questions:', error);
      setError(error.message);
      return [];
    }
  };

  // 获取特定类型的题目
  const getQuestionsByType = async (type) => {
    try {
      return await questionService.getQuestionsByType(type);
    } catch (error) {
      console.error('Error getting questions by type:', error);
      setError(error.message);
      return [];
    }
  };

  // 渲染loading或错误信息
  if (!isAllDataLoaded()) {
    return (
      <div>
        {Object.entries(loading).map(([key, value]) => (
          <div key={key}>{value ? `加载 ${key} 数据中...` : `${key} 数据加载完成`}</div>
        ))}
        {error && (
          <div>
            <p>数据加载失败,请稍后重试</p>
            <button onClick={() => window.location.reload()}>重新加载</button>
          </div>
        )}
      </div>
    );
  }

  const value = {
    questions,
    userProgress,
    wrongQuestions,
    favorites,
    language,
    toggleLanguage,
    recordAnswer,
    recordWrongQuestions,
    getFavoriteQuestions,
    removeWrongQuestion,
    clearAllData,
    getRandomQuestions,
    getQuestionsByType,
    clearWrongQuestions,
    isFavorite: (questionId) => favorites.has(questionId),
  };

  return <QuestionContext.Provider value={value}>{children}</QuestionContext.Provider>;
}

export function useQuestions() {
  return useContext(QuestionContext);
}