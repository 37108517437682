import React from 'react';
import { ChevronLeft, BookOpen, Target } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export default function PracticeTargeted() {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header */}
      <div className="bg-white">
        <div className="flex items-center px-4 py-4">
          <button 
            onClick={() => navigate('/')} 
            className="p-2 hover:bg-gray-100 rounded-lg"
          >
            <ChevronLeft className="w-6 h-6 text-gray-600" />
          </button>
          <h1 className="ml-2 text-lg font-semibold text-gray-800">针对练习</h1>
        </div>
      </div>

      {/* Content */}
      <div className="p-5 space-y-4">
        <div 
          className="bg-white rounded-xl p-6 flex items-center justify-between hover:bg-gray-50"
          onClick={() => navigate('/practice/signs')}  
        >
          <div className="flex items-center space-x-4">
            <div className="w-12 h-12 bg-indigo-600 rounded-full flex items-center justify-center">
              <BookOpen className="w-6 h-6 text-white" />
            </div>
            <div>
              <h3 className="font-medium text-gray-800">道路标志</h3>
              <p className="text-xs text-gray-500">共100道题目</p>
            </div>
          </div>
          <span className="text-gray-400">→</span>
        </div>

        <div 
          className="bg-white rounded-xl p-6 flex items-center justify-between hover:bg-gray-50"
          onClick={() => navigate('/practice/rules')}  
        >
          <div className="flex items-center space-x-4">
            <div className="w-12 h-12 bg-indigo-600 rounded-full flex items-center justify-center">
              <Target className="w-6 h-6 text-white" />
            </div>
            <div>
              <h3 className="font-medium text-gray-800">交通规则</h3>
              <p className="text-xs text-gray-500">共189道题目</p>
            </div>
          </div>
          <span className="text-gray-400">→</span>
        </div>
      </div>
    </div>
  );
}