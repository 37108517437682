import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Check, X, RotateCcw, BookOpen, ChevronLeft } from 'lucide-react';

export default function TestResult() {
  const navigate = useNavigate();
  const location = useLocation();
  const { passed, signsWrong, rulesWrong, answers, questions } = location.state || {};

  if (!location.state) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center">
          <p className="text-gray-600 mb-4">无测试结果数据</p>
          <button
            onClick={() => navigate('/mock-test')}
            className="bg-indigo-600 text-white px-4 py-2 rounded-lg"
          >
            开始新测试
          </button>
        </div>
      </div>
    );
  }

  // 计算统计数据
  const signQuestions = answers.slice(0, 20);
  const ruleQuestions = answers.slice(20);
  const signsCorrect = 20 - signsWrong;
  const rulesCorrect = 20 - rulesWrong;
  const totalCorrect = signsCorrect + rulesCorrect;
  const percentage = Math.round((totalCorrect / 40) * 100);

  return (
    <div className="min-h-screen bg-gray-50 pb-6">
      {/* Header */}
      <div className="bg-white">
        <div className="flex items-center px-4 py-4">
          <button 
            onClick={() => navigate('/')}
            className="p-2 hover:bg-gray-100 rounded-lg"
          >
            <ChevronLeft className="w-6 h-6 text-gray-600" />
          </button>
          <h1 className="ml-2 text-lg font-semibold text-gray-800">测试结果</h1>
        </div>
      </div>

      {/* Result Summary */}
      <div className="p-4">
        <div className="bg-white rounded-xl p-6 mb-4 text-center">
          <div className={`w-20 h-20 rounded-full mx-auto mb-4 flex items-center justify-center
            ${passed ? 'bg-green-100' : 'bg-red-100'}`}>
            {passed ? (
              <Check className="w-10 h-10 text-green-600" />
            ) : (
              <X className="w-10 h-10 text-red-600" />
            )}
          </div>
          <h2 className="text-2xl font-bold text-gray-800 mb-2">
            {passed ? '恭喜通过！' : '未能通过'}
          </h2>
          <p className={`text-lg ${passed ? 'text-green-600' : 'text-red-600'} mb-4`}>
            总得分：{percentage}%
          </p>
          <p className="text-sm text-gray-500">
            {passed 
              ? '您已达到通过标准，可以预约正式考试了！' 
              : '继续练习，下次一定能通过！'}
          </p>
        </div>

        {/* Detailed Scores */}
        <div className="bg-white rounded-xl p-6 mb-4">
          <h3 className="text-lg font-semibold text-gray-800 mb-4">详细得分</h3>
          
          {/* Road Signs Score */}
          <div className="mb-6">
            <div className="flex justify-between items-center mb-2">
              <span className="text-gray-600">路标题</span>
              <div className="text-right">
                <span className={signsWrong > 4 ? 'text-red-500' : 'text-gray-800'}>
                  {signsCorrect}/20
                </span>
                <span className="text-sm text-gray-500 ml-2">
                  ({Math.round((signsCorrect / 20) * 100)}%)
                </span>
              </div>
            </div>
            <div className="h-2 bg-gray-100 rounded-full overflow-hidden">
              <div 
                className={`h-full transition-all ${signsWrong > 4 ? 'bg-red-500' : 'bg-green-500'}`}
                style={{ width: `${(signsCorrect / 20) * 100}%` }}
              />
            </div>
            {signsWrong > 4 && (
              <p className="text-sm text-red-500 mt-1">
                超出错题限制（最多4题）
              </p>
            )}
          </div>

          {/* Road Rules Score */}
          <div className="mb-4">
            <div className="flex justify-between items-center mb-2">
              <span className="text-gray-600">路规题</span>
              <div className="text-right">
                <span className={rulesWrong > 4 ? 'text-red-500' : 'text-gray-800'}>
                  {rulesCorrect}/20
                </span>
                <span className="text-sm text-gray-500 ml-2">
                  ({Math.round((rulesCorrect / 20) * 100)}%)
                </span>
              </div>
            </div>
            <div className="h-2 bg-gray-100 rounded-full overflow-hidden">
              <div 
                className={`h-full transition-all ${rulesWrong > 4 ? 'bg-red-500' : 'bg-blue-500'}`}
                style={{ width: `${(rulesCorrect / 20) * 100}%` }}
              />
            </div>
            {rulesWrong > 4 && (
              <p className="text-sm text-red-500 mt-1">
                超出错题限制（最多4题）
              </p>
            )}
          </div>
        </div>

        {/* Wrong Questions Summary */}
        {(signsWrong > 0 || rulesWrong > 0) && (
          <div className="bg-white rounded-xl p-6 mb-4">
            <h3 className="text-lg font-semibold text-gray-800 mb-4">错题分析</h3>
            {answers.map((answer, index) => {
              if (!answer?.isCorrect) {
                const question = questions[index];
                return (
                  <div key={index} className="mb-4 pb-4 border-b border-gray-100 last:border-0">
                    <div className="flex items-start space-x-3">
                      <div className={`w-8 h-8 rounded-full flex items-center justify-center shrink-0
                        ${index < 20 ? 'bg-green-100 text-green-600' : 'bg-blue-100 text-blue-600'}`}>
                        {index + 1}
                      </div>
                      <div>
                        <p className="text-sm text-gray-800">{question.question_en}</p>
                        <p className="text-xs text-gray-500 mt-1">{question.question_zh}</p>
                        <div className="mt-2 text-xs">
                          <p className="text-red-500">
                            你的答案：{question[`${answer.selectedOption}_zh`]}
                          </p>
                          <p className="text-green-500">
                            正确答案：{question[`${question.answer}_zh`]}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
              return null;
            })}
          </div>
        )}

        {/* Action Buttons */}
        <div className="space-y-3">
          <button 
            onClick={() => navigate('/mock-test')}
            className="w-full bg-indigo-600 text-white py-3 rounded-xl hover:bg-indigo-700 
              flex items-center justify-center space-x-2"
          >
            <RotateCcw className="w-5 h-5" />
            <span>重新测试</span>
          </button>
          
          {(signsWrong > 0 || rulesWrong > 0) && (
            <button 
              onClick={() => navigate('/wrong-questions')}
              className="w-full border border-gray-300 text-gray-700 py-3 rounded-xl 
                hover:bg-gray-50 flex items-center justify-center space-x-2"
            >
              <BookOpen className="w-5 h-5" />
              <span>练习错题</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
}