import React, { useState, useEffect } from 'react';
import { ChevronLeft } from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuestions } from '../contexts/QuestionContext';
import QuestionCard from './QuestionCard';
import CompletionModal from './CompletionModal';

export default function PracticeSession() {
 const navigate = useNavigate();
 const { type } = useParams(); // 'signs' 或 'rules'
 const { getQuestionsByType, loading, recordAnswer, recordWrongQuestions } = useQuestions();
 const [questions, setQuestions] = useState([]);
 const [currentIndex, setCurrentIndex] = useState(0);
 const [selectedAnswers, setSelectedAnswers] = useState([]);
 const [showCompletion, setShowCompletion] = useState(false);
 const [practiceStats, setPracticeStats] = useState({
   total: 0,
   correct: 0,
   wrong: 0
 });

 // 加载特定类型的题目
 useEffect(() => {
   const loadQuestions = async () => {
     const typeQuestions = await getQuestionsByType(type === 'signs' ? "1" : "2");
     console.log('Loaded type questions:', typeQuestions);
     setQuestions(typeQuestions);
     setSelectedAnswers(new Array(typeQuestions.length).fill(null));
   };
   loadQuestions();
 }, [type, getQuestionsByType]);

 // 处理练习完成
 // 处理练习完成
 const handleAnswer = async (option) => {
  if (!questions[currentIndex]) return;

  const currentQuestion = questions[currentIndex];
  const isCorrect = `option_${option}` === currentQuestion.answer;

  // 1. 记录答题结果到 Context
  await recordAnswer(currentQuestion.id, option, isCorrect);

  // 2. 更新本地状态 - 修改这部分
  setSelectedAnswers(prev => {
    const newAnswers = [...prev];
    newAnswers[currentIndex] = {
      questionId: currentQuestion.id,
      selected: option,
      isCorrect: isCorrect
    };
    return newAnswers;
  });

  // 3. 如果答错了，立即记录到错题本
  if (!isCorrect) {
    await recordWrongQuestions([currentQuestion]);
  }

  // 4. 延迟后进入下一题或完成
  setTimeout(() => {
    if (currentIndex < questions.length - 1) {
      setCurrentIndex(prev => prev + 1);
    } else {
      handleComplete();
    }
  }, 1000);
};

// 处理练习完成
const handleComplete = async () => {
  const completedAnswers = selectedAnswers.filter(answer => answer !== null);
  const totalAnswered = completedAnswers.length;
  const correctCount = completedAnswers.filter(answer => answer.isCorrect === true).length;
  const wrongCount = completedAnswers.filter(answer => answer.isCorrect === false).length;

  console.log('答题统计:', {
    完成题数: totalAnswered,
    正确: correctCount,
    错误: wrongCount,
    答案详情: selectedAnswers
  });

  // 收集所有答错的题目
  const wrongQuestions = questions.filter((_, index) => 
    selectedAnswers[index] && selectedAnswers[index].isCorrect === false
  );
  
  // 确保所有错题都被记录
  if (wrongQuestions.length > 0) {
    await recordWrongQuestions(wrongQuestions);
  }

  // 更新统计数据
  const stats = {
    total: totalAnswered,
    correct: correctCount,
    wrong: wrongCount
  };
  
  console.log('最终统计:', stats);
  setPracticeStats(stats);
  setShowCompletion(true);
};

 if (loading) {
   return (
     <div className="min-h-screen bg-gray-50 flex items-center justify-center">
       <div className="text-gray-500">加载中...</div>
     </div>
   );
 }

 if (!questions || questions.length === 0) {
   return (
     <div className="min-h-screen bg-gray-50 flex items-center justify-center">
       <div className="text-gray-500">暂无题目</div>
     </div>
   );
 }

 return (
   <div className="min-h-screen bg-gray-50">
     {/* Header */}
     <div className="bg-white">
       <div className="flex items-center px-4 py-4">
         <button 
           onClick={() => navigate('/practice-targeted')}
           className="p-2 hover:bg-gray-100 rounded-lg"
         >
           <ChevronLeft className="w-6 h-6 text-gray-600" />
         </button>
         <h1 className="ml-2 text-lg font-semibold text-gray-800">
           {type === 'signs' ? '路标练习' : '路规练习'}
         </h1>
         <span className="ml-auto text-sm text-gray-500">
           {currentIndex + 1}/{questions.length}
         </span>
       </div>
     </div>

     {/* Question Content */}
     <div className="p-4">
       <QuestionCard
         question={questions[currentIndex]}
         onSelect={handleAnswer}
         showFavoriteButton={false} 
       />
     </div>

     {/* Progress Bar */}
     <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-100 p-4">
       <div className="h-1 bg-gray-200 rounded-full">
         <div 
           className="h-1 bg-indigo-600 rounded-full transition-all"
           style={{ width: `${(currentIndex + 1) / questions.length * 100}%` }}
         />
       </div>
     </div>

     {/* Completion Modal */}
     {showCompletion && (
        <CompletionModal
          stats={practiceStats}
          onReview={async () => {
            const wrongQuestions = questions.filter((_, index) => 
              selectedAnswers[index] !== null && !selectedAnswers[index].isCorrect
            );
            if (wrongQuestions.length > 0) {
              // 确保错题被记录
              await recordWrongQuestions(wrongQuestions);
              // 设置练习题目
              sessionStorage.setItem('practiceQuestions', JSON.stringify(wrongQuestions));
              navigate('/practice-wrong');
            }
          }}
          onRestart={() => {
            setCurrentIndex(0);
            setSelectedAnswers(new Array(questions.length).fill(null));
            setShowCompletion(false);
          }}
          onExit={() => navigate('/practice-targeted')}
          mode="practice"
          showReview={practiceStats.wrong > 0}
        />
      )}
   </div>
 );
}