import React from 'react';

export default function TestProgress({ answers }) {
  // answers 格式: Array(40) 每个元素是 { type: "signs"/"rules", isCorrect: true/false/null }
  
  return (
    <div className="p-4 bg-white border-t border-gray-100">
      <div className="grid grid-cols-10 gap-1 mb-1">
        {/* 前20个格子 - Road Signs */}
        {Array.from({ length: 20 }).map((_, index) => {
          const answer = answers[index];
          const bgColor = !answer ? 'bg-gray-200' : 
                         answer.isCorrect ? 'bg-green-500' : 'bg-red-500';
          
          return (
            <div 
              key={`signs-${index}`}
              className={`h-2 rounded-sm ${bgColor} transition-colors`}
              title={`题目 ${index + 1}`}
            />
          );
        })}
      </div>
      
      <div className="grid grid-cols-10 gap-1">
        {/* 后20个格子 - Road Rules */}
        {Array.from({ length: 20 }).map((_, index) => {
          const answer = answers[index + 20];
          const bgColor = !answer ? 'bg-gray-200' : 
                         answer.isCorrect ? 'bg-blue-500' : 'bg-red-500';
          
          return (
            <div 
              key={`rules-${index}`}
              className={`h-2 rounded-sm ${bgColor} transition-colors`}
              title={`题目 ${index + 21}`}
            />
          );
        })}
      </div>

      {/* 图例 */}
      <div className="mt-3 flex items-center justify-center space-x-4 text-xs text-gray-500">
        <div className="flex items-center space-x-1">
          <div className="w-3 h-3 bg-green-500 rounded"/>
          <span>路标题答对</span>
        </div>
        <div className="flex items-center space-x-1">
          <div className="w-3 h-3 bg-blue-500 rounded"/>
          <span>路规题答对</span>
        </div>
        <div className="flex items-center space-x-1">
          <div className="w-3 h-3 bg-red-500 rounded"/>
          <span>答错</span>
        </div>
      </div>
    </div>
  );
}