import React, { useState } from 'react';
import { ChevronLeft, BookOpen, Target, Heart, History } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useQuestions } from '../contexts/QuestionContext';

export default function FavoriteQuestions() {
  const navigate = useNavigate();
  const { getFavoriteQuestions, toggleFavorite, userProgress } = useQuestions();
  const [activeType, setActiveType] = useState('all');
  const [showHistory, setShowHistory] = useState(false);

  const favoriteQuestions = getFavoriteQuestions();
  
  // 按类型筛选收藏题目
  const filteredQuestions = favoriteQuestions.filter(q => {
    if (activeType === 'all') return true;
    if (activeType === 'signs') return q.type === "1";
    if (activeType === 'rules') return q.type === "2";
    return true;
  });

  // 统计数据
  const stats = {
    total: favoriteQuestions.length,
    signs: favoriteQuestions.filter(q => q.type === "1").length,
    rules: favoriteQuestions.filter(q => q.type === "2").length,
    practiced: favoriteQuestions.filter(q => userProgress?.answers?.[q.id]).length
  };

  // 获取题目练习历史
  const getQuestionHistory = (questionId) => {
    const history = userProgress?.answers?.[questionId] || [];
    return Array.isArray(history) ? history : [history];
  };

  // 开始练习收藏题目
  const startPractice = (questions) => {
    sessionStorage.setItem('practiceQuestions', JSON.stringify(questions));
    navigate('/practice-favorites');
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header */}
      <div className="bg-white">
        <div className="flex items-center justify-between px-4 py-4">
          <div className="flex items-center">
            <button 
              onClick={() => navigate(-1)}
              className="p-2 hover:bg-gray-100 rounded-lg"
            >
              <ChevronLeft className="w-6 h-6 text-gray-600" />
            </button>
            <h1 className="ml-2 text-lg font-semibold text-gray-800">收藏题目</h1>
          </div>
          <button
            onClick={() => setShowHistory(!showHistory)}
            className={`p-2 rounded-lg ${showHistory ? 'bg-indigo-100 text-indigo-600' : 'hover:bg-gray-100'}`}
          >
            <History className="w-6 h-6" />
          </button>
        </div>
      </div>

      {/* Stats Card */}
      <div className="p-4">
        <div className="bg-white rounded-xl p-6 mb-4">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-lg font-semibold text-gray-800">收藏统计</h2>
            <span className="text-sm text-gray-500">
              已练习 {stats.practiced}/{stats.total}
            </span>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="p-4 bg-blue-50 rounded-lg">
              <div className="flex items-center justify-between mb-2">
                <span className="text-blue-600">路标题</span>
                <span className="text-blue-600 font-semibold">{stats.signs}题</span>
              </div>
              <div className="text-sm text-blue-500">
                {stats.signs > 0 ? '点击下方分类练习' : '暂无收藏'}
              </div>
            </div>
            <div className="p-4 bg-purple-50 rounded-lg">
              <div className="flex items-center justify-between mb-2">
                <span className="text-purple-600">路规题</span>
                <span className="text-purple-600 font-semibold">{stats.rules}题</span>
              </div>
              <div className="text-sm text-purple-500">
                {stats.rules > 0 ? '点击下方分类练习' : '暂无收藏'}
              </div>
            </div>
          </div>
        </div>

        {/* Filter Tabs */}
        <div className="bg-white rounded-xl p-4 mb-4">
          <div className="flex space-x-2">
            <button
              onClick={() => setActiveType('all')}
              className={`flex-1 py-2 px-4 rounded-lg text-sm ${
                activeType === 'all' 
                  ? 'bg-indigo-600 text-white' 
                  : 'bg-gray-100 text-gray-600'
              }`}
            >
              全部 ({stats.total})
            </button>
            <button
              onClick={() => setActiveType('signs')}
              className={`flex-1 py-2 px-4 rounded-lg text-sm ${
                activeType === 'signs' 
                  ? 'bg-indigo-600 text-white' 
                  : 'bg-gray-100 text-gray-600'
              }`}
            >
              路标题 ({stats.signs})
            </button>
            <button
              onClick={() => setActiveType('rules')}
              className={`flex-1 py-2 px-4 rounded-lg text-sm ${
                activeType === 'rules' 
                  ? 'bg-indigo-600 text-white' 
                  : 'bg-gray-100 text-gray-600'
              }`}
            >
              路规题 ({stats.rules})
            </button>
          </div>
        </div>

        {/* Questions List */}
        {filteredQuestions.length > 0 ? (
          <div className="space-y-3">
            {filteredQuestions.map((question) => {
              const history = getQuestionHistory(question.id);
              const correctCount = history.filter(h => h.isCorrect).length;
              
              return (
                <div 
                  key={question.id}
                  className="bg-white rounded-xl p-4"
                >
                  <div className="flex items-start space-x-3">
                    <div className={`w-10 h-10 rounded-full flex items-center justify-center shrink-0
                      ${question.type === "1" ? 'bg-blue-100 text-blue-600' : 'bg-purple-100 text-purple-600'}`}
                    >
                      {question.type === "1" ? (
                        <BookOpen className="w-5 h-5" />
                      ) : (
                        <Target className="w-5 h-5" />
                      )}
                    </div>
                    <div className="flex-1">
                      <p className="text-sm text-gray-800 mb-1">{question.question_en}</p>
                      <p className="text-xs text-gray-500">{question.question_zh}</p>
                      
                      {showHistory && history.length > 0 && (
                        <div className="mt-2 p-2 bg-gray-50 rounded-lg">
                          <div className="text-xs text-gray-600 mb-1">
                            练习历史：{history.length}次
                            {correctCount > 0 && ` (答对${correctCount}次)`}
                          </div>
                          <div className="flex space-x-1">
                            {history.map((h, i) => (
                              <div 
                                key={i}
                                className={`w-2 h-2 rounded-full ${
                                  h.isCorrect ? 'bg-green-500' : 'bg-red-500'
                                }`}
                                title={`第${i + 1}次：${h.isCorrect ? '正确' : '错误'}`}
                              />
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                    <button
                      onClick={() => toggleFavorite(question.id)}
                      className="p-2 text-red-500 hover:bg-red-50 rounded-lg transition-colors"
                    >
                      <Heart className="w-5 h-5 fill-current" />
                    </button>
                  </div>
                </div>
              );
            })}
            
            {/* Practice Button */}
            <button
              onClick={() => startPractice(filteredQuestions)}
              className="w-full bg-indigo-600 text-white py-3 rounded-xl mt-4 hover:bg-indigo-700"
            >
              练习这些题目
            </button>
          </div>
        ) : (
          <div className="text-center py-8 bg-white rounded-xl">
            <div className="text-gray-400">暂无收藏题目</div>
          </div>
        )}
      </div>
    </div>
  );
}