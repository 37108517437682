import React from 'react';
import { BookOpen, Target, ClipboardList, History } from 'lucide-react';

import { useNavigate } from 'react-router-dom';

import { useQuestions } from '../contexts/QuestionContext';

export default function HomePage() {
    const navigate = useNavigate();
    const { questions } = useQuestions();  // 获取题目数据

  return (
    <div className="min-h-screen bg-gray-50">

      {/* Header Section */}
      <div className="p-5">
        <h1 className="text-xl font-semibold text-gray-800">你好!</h1>
        <p className="text-gray-500 text-sm">请牢记并理解每一道题的正确答案</p>
      </div>

      {/* Banner Section */}
      <div className="px-5 mb-6">
        <div className="bg-indigo-600 rounded-2xl p-6 text-white">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-semibold">G1 驾照考试</h2>
            <span className="text-indigo-200">练习版</span>
          </div>
          <div className="text-sm text-indigo-200">
            考试共40道题目 | 通过率要求: 80%
          </div>
          <div className="text-sm text-indigo-200">
            刘教练微信: Poseidon_On
          </div>
          <div className="text-sm text-indigo-200">
            网站地址: g1test.site
          </div>
        </div>
      </div>

      {/* Main Menu */}
      <div className="px-5 space-y-3">
        <div 
            className="bg-white rounded-xl p-4 flex items-center justify-between hover:bg-gray-50"
            onClick={() => navigate('/practice-all')}
        >
          <div className="flex items-center space-x-4">
            <div className="w-10 h-10 bg-indigo-600 rounded-full flex items-center justify-center">
              <BookOpen className="w-5 h-5 text-white" />
            </div>
            <div>
              <h3 className="font-medium text-gray-800">题库练习</h3>
              <p className="text-xs text-gray-500">练习全部{questions.length}道题目</p>
            </div>
          </div>
          <span className="text-gray-400">→</span>
        </div>

        <div 
            className="bg-white rounded-xl p-4 flex items-center justify-between hover:bg-gray-50"
            onClick={() => navigate('/practice-targeted')}
        >
          <div className="flex items-center space-x-4">
            <div className="w-10 h-10 bg-indigo-600 rounded-full flex items-center justify-center">
              <Target className="w-5 h-5 text-white" />
            </div>
            <div>
              <h3 className="font-medium text-gray-800">针对练习</h3>
              <p className="text-xs text-gray-500">分类练习路标或路规题目</p>
            </div>
          </div>
          <span className="text-gray-400">→</span>
        </div>

        <div 
            className="bg-white rounded-xl p-4 flex items-center justify-between hover:bg-gray-50"
            onClick={() => navigate('/mock-test')}
        >
          <div className="flex items-center space-x-4">
            <div className="w-10 h-10 bg-indigo-600 rounded-full flex items-center justify-center">
              <ClipboardList className="w-5 h-5 text-white" />
            </div>
            <div>
              <h3 className="font-medium text-gray-800">模拟测试</h3>
              <p className="text-xs text-gray-500">40道题目的模拟考试</p>
            </div>
          </div>
          <span className="text-gray-400">→</span>
        </div>

        <div 
            className="bg-white rounded-xl p-4 flex items-center justify-between hover:bg-gray-50"
            onClick={() => navigate('/wrong-questions')}
        >
          <div className="flex items-center space-x-4">
            <div className="w-10 h-10 bg-indigo-600 rounded-full flex items-center justify-center">
              <History className="w-5 h-5 text-white" />
            </div>
            <div>
              <h3 className="font-medium text-gray-800">错题本</h3>
              <p className="text-xs text-gray-500">重点练习错误题目</p>
            </div>
          </div>
          <span className="text-gray-400">→</span>
        </div>
      </div>
    </div>
  );
}